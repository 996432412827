import { LogLevel } from '@azure/msal-browser';

const protectedResourceMap = new Map([
  ['https://graph.microsoft.com/v1.0/me', ['user.read']],
  [
    'https://authorizationapidev.gallagherre.com/api/',
    ['api://35a3b1d2-e4fe-45f6-a8d2-f6e8c2fdc48f/user_impersonation']
  ],
  [
    'https://cmiapidev.gallagherre.com/api/',
    ['api://772d4576-50f3-48a3-95f7-b4f4de36b281/accessuser']
  ]
]);

const b2cProtectedResourceMap = new Map([
  [
    'https://authorizationapidev.gallagherre.com/api/',
    ['https://ajgb2c.onmicrosoft.com/1b747f3b-a7c1-4514-9e74-f8ee3da048d8/user_impersonation']
  ],
  [
    'https://cmiapidev.gallagherre.com/api/',
    ['https://ajgb2c.onmicrosoft.com/1b747f3b-a7c1-4514-9e74-f8ee3da048d8/user_impersonation']
  ]
]);

export const environment = {
  environment: 'dev',
  production: false,
  loggingEnabled: true,
  logLevel: LogLevel.Verbose,

  clientId: '1230c88f-31e6-4f73-950a-33fc944c8136',
  authority: 'https://login.microsoftonline.com/6cacd170-f897-4b19-ac58-46a23307b80a',
  customScopes: [],
  protectedResourceMap,
  applicationName: 'gallagherre-cmi',
  rolesCacheSeconds: 10,
  authenticationCacheSeconds: 10,

  b2cClientId: '45efb001-1b11-42ff-951f-e6735ef3159e',
  b2cAuthority: 'https://ajgb2c.b2clogin.com/ajgb2c.onmicrosoft.com/B2C_1_gre_b2c_cmi_si',
  b2cResetPasswordAuthority:
    'https://ajgb2c.b2clogin.com/ajgb2c.onmicrosoft.com/B2C_1_gre_b2c_cmi_pass_reset/',
  b2cKnownAuthorities: ['ajgb2c.b2clogin.com'],
  b2cCustomScopes: [],
  b2cProtectedResourceMap,

  redirectUri: 'https://cmidev.gallagherre.com/login',
  postLogoutRedirectUri: 'https://cmidev.gallagherre.com/',
  authorisationUrl: 'https://authorizationapidev.gallagherre.com/api/',
  cmiApiUri: 'https://cmiapidev.gallagherre.com'
};
